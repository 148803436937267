import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useState } from 'react';
import { Shipment } from '../../api';
import { formatDate, renderTextIfDefined } from '../../helpers';
import { useDeliveries, useUser } from '../../hooks/api';
import { useToast } from '../../hooks/error';

type Props = { deliveries: Shipment[] };

export const DeliveriesTable = (props: Props) => {
  const [linkError, setLinkError] = useState<string | null>(null);
  const { deliveries } = props || {};
  const { openShipmentTrackingLink } = useDeliveries();
  const { user } = useUser();
  const { customer } = user || {};
  const { id: customerId } = customer || {};

  const Toast = useToast({
    message: linkError,
    onExit: () => {
      setLinkError(null);
    },
  });

  const handleOpenShipmentTrackingLink = async (
    shipmentId: number,
    customerId: number
  ) => {
    try {
      if (linkError) {
        setLinkError(null);
      }
      await openShipmentTrackingLink(shipmentId, customerId);
    } catch (e) {
      setLinkError('Spårningslänken saknas eller fungerar ej!');
      console.error(e);
    }
  };

  return (
    <>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell variant="head">Datum</TableCell>
              <TableCell variant="head">Antal kollin</TableCell>
              <TableCell variant="head">Speditör</TableCell>
              <TableCell variant="head">Frakt#</TableCell>
              <TableCell variant="head">Spåra</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {deliveries.map((shipment, i) => {
              const {
                consignmentNumber,
                courier,
                id,
                numberOfPackages,
                transportDate,
              } = shipment || {};
              return (
                <TableRow key={i}>
                  <TableCell>
                    {transportDate && formatDate(transportDate)}
                  </TableCell>
                  <TableCell>
                    {renderTextIfDefined(numberOfPackages, 'st')}
                  </TableCell>
                  <TableCell>{renderTextIfDefined(courier)}</TableCell>
                  <TableCell>
                    {renderTextIfDefined(consignmentNumber)}
                  </TableCell>
                  <TableCell
                    variant="link"
                    onClick={() =>
                      id &&
                      customerId &&
                      handleOpenShipmentTrackingLink(customerId, id)
                    }
                  >
                    {renderTextIfDefined('Länk')}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      {Toast}
    </>
  );
};
