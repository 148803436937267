import { LinearProgress, Paper, Typography } from '@mui/material';
import { AlertNoOrdersFound, OrdersTable } from '../../components';
import styles from '../../constants/styles';
import { useOrders } from '../../hooks/api';

export function LatestOrders() {
  const { orders, loading } = useOrders();
  const latestOrders = orders.slice(0, 5);

  return (
    <>
      <Typography style={{ marginBottom: 10 }} component="h5" variant="h5">
        Order för leverans
      </Typography>
      <Paper style={styles.base.paper}>
        {loading ? (
          <LinearProgress />
        ) : latestOrders?.length > 0 ? (
          <OrdersTable orders={latestOrders} />
        ) : (
          <AlertNoOrdersFound />
        )}
      </Paper>
    </>
  );
}
