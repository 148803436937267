import { ArrowForward } from '@mui/icons-material';
import { LinearProgress, Paper, Typography } from '@mui/material';
import CountUp from 'react-countup';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATHS } from '../../constants/routes';
import styles from '../../constants/styles';
import { useOrders } from '../../hooks/api';

export function OrdersCount() {
  const { orders, loading } = useOrders();
  const navigate = useNavigate();

  return (
    <>
      <div
        onClick={() => navigate(ROUTE_PATHS.ORDERS)}
        style={{ cursor: 'pointer' }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 10,
          }}
        >
          <Typography component="h5" variant="h5">
            Antal orderrader
          </Typography>
          <ArrowForward />
        </div>
        <Paper style={{ ...styles.base.counterContainer }}>
          <Typography
            component="h2"
            variant="h1"
            id="count"
            style={{ ...styles.counters.number }}
          >
            <CountUp end={orders?.length} />
            <LinearProgress sx={{ opacity: loading ? 1 : 0 }} />
          </Typography>
        </Paper>
      </div>
    </>
  );
}
