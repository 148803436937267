import { PlannedOrderType } from '../api';
import colors from '../constants/colors';

/**
 * Returns background and text color based on the planned order type
 * @param {PlannedOrderType} orderType - The type of planned order (SALES, BLANKET, or other)
 * @returns {Object} An object containing background and text color values
 */
export const renderColorByOrderType = (
  orderType: PlannedOrderType
): { background: string; textColor: string } => {
  if (
    orderType === PlannedOrderType.SALES ||
    orderType === PlannedOrderType.BLANKET
  ) {
    return { background: colors.errorLight, textColor: colors.error };
  }
  return { background: colors.successLight, textColor: colors.success };
};
/**
 * Returns text color based on the inventory balance value
 * @param {number} inventoryBalance - The inventory balance number
 * @param {string} [positiveBalanceColor=colors.black] - Color to use for zero or positive values
 * @param {string} [negativeBalanceColor=colors.error] - Color to use for negative values
 * @returns {Object} An object containing text color value
 */
export const renderColorByInventoryBalance = (
  inventoryBalance: number,
  positiveBalanceColor: string = colors.success,
  negativeBalanceColor: string = colors.error
): { textColor: string } => {
  if (inventoryBalance < 0) {
    return { textColor: negativeBalanceColor };
  }
  return { textColor: positiveBalanceColor };
};
