import {
  Alert,
  AlertProps,
  AlertSlotsAndSlotProps,
  Snackbar,
  SnackbarCloseReason,
  SnackbarProps,
} from '@mui/material';
import { SyntheticEvent, useCallback, useEffect, useState } from 'react';

type Props = {
  message?: string | null;
  onExit?: () => void;
} & Pick<SnackbarProps, 'autoHideDuration'> &
  Pick<AlertProps & AlertSlotsAndSlotProps, 'severity' | 'variant'>;

export const useToast = (props: Props) => {
  const {
    message,
    onExit,
    autoHideDuration = 5000,
    severity = 'error',
    variant = 'filled',
  } = props || {};
  const [open, setOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState<string | null>(null);

  const handleClose = useCallback(
    (_?: SyntheticEvent | Event, reason?: SnackbarCloseReason) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpen(false);
      if (onExit) {
        onExit();
      }
    },
    [onExit]
  );

  useEffect(() => {
    if (message) {
      setOpen(false);
      const timer = setTimeout(() => {
        setToastMessage(message);
        setOpen(true);
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [message]);

  if (!toastMessage) {
    return null;
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
    >
      <Alert
        severity={severity}
        variant={variant}
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          display: 'flex',
          gap: 1,
          '& .MuiAlert-message': {
            padding: 0,
          },
          '& .MuiAlert-action': {
            padding: 0,
          },
          '& .MuiAlert-icon': {
            margin: 0,
            marginRight: 0.5,
          },
        }}
        onClose={handleClose}
      >
        {toastMessage}
      </Alert>
    </Snackbar>
  );
};
