import { ChangeEvent } from 'react';
import { useLocation } from 'react-router-dom';
import { ROUTE_PATHS } from '../../constants/routes';
import styles from '../../constants/styles';
import { useCustomers, useUser } from '../../hooks/api';
import { getCustomerData } from '../../services/api';

export function AdminCustomerPicker() {
  const { user, changeCustomer } = useUser();
  const { customers } = useCustomers();
  const location = useLocation();
  const isOnArticlePage = location.pathname.includes('article/');
  const isOnDashboardPage = location.pathname === ROUTE_PATHS.DASHBOARD;
  const { customer } = user || {};

  async function handleSelectChange(event: ChangeEvent<HTMLSelectElement>) {
    let code = event.target.value;
    if (code !== '') {
      try {
        changeCustomer(customers.filter((c) => c.code === code)[0]);
        await getCustomerData();
      } catch (e) {
        console.log(e);
      }
    } else {
    }
  }

  return (
    user?.isAdmin &&
    !isOnArticlePage && (
      <div style={{ ...styles.admin.customerSelectContainer }}>
        <div style={{ textAlign: 'right' }}>
          {isOnDashboardPage && (
            <span style={{ display: 'block', marginBottom: 25 }}>
              Du är inloggad som administratör i portalen och måste först välja
              en kund att arbeta med i listan.
            </span>
          )}

          <select
            value={customer?.code ? customer.code : ''}
            onChange={handleSelectChange}
            style={{
              ...styles.base.selectField,
              ...styles.admin.customerSelect,
            }}
          >
            <option value="">Välj en kund</option>
            {customers?.map(({ code, name }, i) => {
              return (
                <option value={code ?? ''} key={i}>
                  {`${code} ${name}`}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    )
  );
}
