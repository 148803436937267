import { useCallback } from 'react';
import {
  getDeliveriesByCustomer,
  getShipmentTrackingLink,
} from '../../services/api';
import { useAppSelector } from '../redux/useAppSelector';

export const useDeliveries = () => {
  const getDeliveries = useCallback(async () => {
    await getDeliveriesByCustomer();
  }, []);

  const openShipmentTrackingLink = async (
    customerId: number,
    shipmentId: number
  ) => {
    const { url } =
      (await getShipmentTrackingLink(customerId, shipmentId)) || {};
    if (url) {
      window.open(url, '_blank');
      return;
    }
    throw new Error('Shipment tracking link does not exist');
  };

  const deliveries = useAppSelector((state) => state.deliveries);
  return { ...deliveries, getDeliveries, openShipmentTrackingLink };
};
