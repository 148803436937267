import { Grid, Paper } from '@mui/material';
import { PropsWithChildren } from 'react';
import { ProfileGridItem } from '../../components';
import styles from '../../constants/styles';

export function ProfileGrid({
  children,
}: PropsWithChildren<{
  children:
    | React.ReactElement<typeof ProfileGridItem>
    | React.ReactElement<typeof ProfileGridItem>[];
}>) {
  return (
    <Paper style={{ ...styles.base.paper }}>
      <Grid container spacing={3}>
        {children}
      </Grid>
    </Paper>
  );
}
