import { Grid, Paper, Typography } from '@mui/material';

import {
  ChangePasswordForm,
  PageHeader,
  ProfileGrid,
  ProfileGridItem,
} from '../components';
import styles from '../constants/styles';
import { useUser } from '../hooks/api';

export function Profile() {
  const { user } = useUser();
  const { cellPhoneNumber, name, emailAddress, phoneNumber, customer } =
    user || {};

  return (
    <>
      <PageHeader
        heading="Min profil"
        text="Detta är den information vi har om dig. Om något inte stämmer är du varmt välkommen att kontakta oss så löser vi det. Du kan även ändra lösenord för ditt konto här i portalen."
      />
      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Typography component="h2" variant="h5" style={{ marginBottom: 10 }}>
            {user?.customer?.name}
          </Typography>
          <ProfileGrid>
            <ProfileGridItem title="Kundkod" value={customer?.code} />
            <ProfileGridItem title="Namn" value={name} />
            <ProfileGridItem title="Epost" value={emailAddress} />
            <ProfileGridItem title="Telefon" value={phoneNumber} />
            <ProfileGridItem title="Mobil" value={cellPhoneNumber} />
          </ProfileGrid>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <Typography component="h2" variant="h5" style={{ marginBottom: 10 }}>
            Byt Lösenord
          </Typography>
          <Paper id="ChangePassword" style={{ ...styles.base.paper }}>
            <ChangePasswordForm />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
