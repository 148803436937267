import { useCallback, useState } from 'react';
import { PartDetails } from '../../api';
import { getArticleDetails } from '../../services/api';

export const useArticle = (id?: number) => {
  const [error, setError] = useState<unknown>();
  const [loading, setLoading] = useState(true);
  const [articleDetails, setArticleDetails] = useState<PartDetails>();

  const getArticle = useCallback(async () => {
    try {
      if (id) {
        setError(undefined);
        setLoading(true);
        const article = await getArticleDetails(id);
        setArticleDetails(article);
      }
    } catch (e) {
      console.log(e);
      setError(e);
    } finally {
      setLoading(false);
    }
  }, [id]);

  return { articleDetails, loading, error, getArticle };
};
