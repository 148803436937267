import CssBaseline from '@mui/material/CssBaseline';
import Hidden from '@mui/material/Hidden';
import { styled } from '@mui/system';
import { useState } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { AdminCustomerPicker } from '../components/Admin/AdminCustomerPicker';
import colors from '../constants/colors';
import { ROUTES } from '../constants/routes';
import Header from './Header';
import Navigator from './Navigator';

const drawerWidth = 320;

const Root = styled('div')({
  display: 'flex',
  minHeight: '100vh',
});

const Drawer = styled('nav')(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    width: drawerWidth,
    flexShrink: 0,
  },
}));

const App = styled('div')({
  width: '100%',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
});

const Main = styled('main')(({ theme }) => ({
  flex: 1,
  padding: theme.spacing(4, 4),
  maxWidth: '120rem',
  width: '100%',
  alignSelf: 'center',
}));

function Paperbase() {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleToggleDrawer = () => {
    setMobileOpen((prev) => !prev);
  };

  return (
    <Router>
      <Root style={{ backgroundColor: colors.background }}>
        <CssBaseline />
        <Drawer>
          <Hidden smUp>
            <Navigator
              PaperProps={{ style: { width: drawerWidth } }}
              variant="temporary"
              open={mobileOpen}
              onToggleDrawer={handleToggleDrawer}
            />
          </Hidden>
          <Hidden smDown>
            <Navigator PaperProps={{ style: { width: drawerWidth } }} />
          </Hidden>
        </Drawer>
        <App>
          <Header onToggleDrawer={handleToggleDrawer} />
          <Main>
            <AdminCustomerPicker />
            <Routes>
              {ROUTES.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  element={<route.component />}
                />
              ))}
            </Routes>
          </Main>
        </App>
      </Root>
    </Router>
  );
}

export default Paperbase;
