/**
 * Renders a value with an optional unit, returning a dash if the value is undefined, null, or empty
 * @param value - The value to render
 * @param unit - Optional unit to append to the value
 * @returns Formatted string with value and optional unit, or '-' if value is invalid
 */
export const renderTextIfDefined = (
  value: number | string | undefined | null,
  unit?: string
): string => {
  if (value === undefined || value === null || value === '') return '-';
  return `${value}${unit ? ` ${unit}` : ''}`;
};

/**
 * Sorts two dates in descending order (newest first)
 * @param a - First date to compare
 * @param b - Second date to compare
 * @returns Negative number if b is earlier than a, positive if b is later than a, 0 if equal or invalid
 */
export const sortByDate = (
  a?: string | number | Date,
  b?: string | number | Date
) => {
  if (!a || !b) return 0;
  return new Date(b).getTime() - new Date(a).getTime();
};

/**
 * Sorts an array of objects by their period property in descending order (latest first)
 * @param orders - Array of objects containing an optional period property
 * @returns A new sorted array of objects, sorted by period date in descending order
 * @template T - Type extending an object with an optional period string property
 */
export const sortByPeriod = <T extends { period?: string }>(
  orders: T[]
): T[] => {
  return [...orders].sort((a, b) => {
    return sortByDate(a.period, b.period);
  });
};
