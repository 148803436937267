import { TextField, TextFieldProps } from '@mui/material';

export function PasswordInput(props: TextFieldProps) {
  return (
    <TextField
      {...props}
      variant="outlined"
      type="password"
      style={{ marginBottom: 25 }}
      fullWidth
    />
  );
}
