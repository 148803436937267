import { Alert, LinearProgress, Paper, TextField } from '@mui/material';
import React, { ChangeEvent, useMemo } from 'react';
import { Part } from '../api';
import { ArticlesTable, PageHeader } from '../components';
import styles from '../constants/styles';
import { useArticles } from '../hooks/api';
import { useFilterArray } from '../hooks/helpers';

export function Articles() {
  const [query, setQuery] = React.useState('');

  function handleInputChange(
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) {
    let query = event.target.value.toLowerCase();
    setQuery(query);
  }

  const resetFilter = () => {
    setQuery('');
  };

  React.useEffect(() => {
    resetFilter();
  }, []);

  const { articles, loading } = useArticles();

  const propertyNames: (keyof Part)[] = useMemo(
    () => [
      'customerPartNumber',
      'description',
      'drawingNumber',
      'extraDescription',
      'id',
      'partNumber',
      'revisionNumber',
    ],
    []
  );

  const filteredArticles = useFilterArray(articles, query, propertyNames);

  return (
    <>
      <PageHeader
        heading={'Artiklar hos ATORP'}
        text={
          'I listan ser du alla din artiklar hos ATORP. Du kan även sortera tabellen, eller klicka dig in på en specifik artikel.'
        }
      />
      <Paper style={{ ...styles.base.paper }}>
        <TextField
          id="outlined-basic"
          label="Skriv in vad du vill söka på..."
          variant="outlined"
          style={{ ...styles.base.textField, marginBottom: 15 }}
          fullWidth
          onChange={handleInputChange}
        />
        {loading && <LinearProgress />}
        {!loading && (
          <>
            {filteredArticles.length > 0 ? (
              <ArticlesTable data={filteredArticles} />
            ) : (
              <Alert severity="info">{'Inga artiklar hittades'}</Alert>
            )}
          </>
        )}
      </Paper>
    </>
  );
}
