import { Paper } from '@mui/material';
import { PageHeader, VideoPlayer } from '../components';
import styles from '../constants/styles';

export function PortalInfo() {
  return (
    <>
      <PageHeader heading={'Introduktionsfilm Kundportal'} />
      <Paper className="Portal101" style={{ ...styles.base.paper }}>
        <>
          <VideoPlayer
            url="https://vimeo.com/539190687"
            showAsModal={false}
            coverUrl={require('../assets/cover.jpg')}
          />
        </>
      </Paper>
    </>
  );
}
