import { CSSProperties } from 'react';
import colors from './colors';
const isIE = /*@cc_on!@*/ false || !!document.DOCUMENT_NODE;

const base: {
  [key: string]: CSSProperties;
} = {
  paper: {
    padding: 20,
  },
  textField: {
    marginBottom: 25,
  },
  selectField: {
    height: 30,
    backgroundColor: colors.lightGray,
    marginTop: 10,
  },
  pageHeadingContainer: {
    marginBottom: 50,
  },
  pageHeading: {
    fontSize: '2.5rem',
    color: colors.primary,
    fontWeight: 'bold',
  },
  pageHeadingText: {
    fontSize: '1rem',
    color: colors.gray,
    marginTop: 5,
    display: 'block',
    maxWidth: 700,
  },
  counterContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
    height: 300,
  },
};

const admin: {
  [key: string]: CSSProperties;
} = {
  customerSelectContainer: {
    marginBottom: 25,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  customerSelect: {
    outline: 0,
    border: 0,
    margin: 0,
    MozAppearance: 'none',
    padding: '0 5px',
  },
};

const article: {
  [key: string]: CSSProperties;
} = {
  articlePageHeading: {
    marginBottom: 25,
    marginTop: 25,
  },
  articleHeading: {
    color: colors.primary,
    fontWeight: '900',
  },
  articleStock: {
    fontWeight: 'bold',
  },
  articleInfoContainer: {
    marginTop: 25,
    borderTop: '1px solid #ccc',
    borderBottom: '1px solid #ccc',
    padding: '15px 0',
  },
  articleInfoHeading: {
    fontWeight: '900',
  },
  articleInfoText: {
    color: colors.gray,
    fontSize: 16,
    maxWidth: '90%',
    display: 'block',
  },
};

const counters: {
  [key: string]: CSSProperties;
} = {
  number: {
    color: colors.primary,
    fontWeight: '900',
    width: '100%', // ie shit
    textAlign: 'center', // ie shit
    lineHeight: isIE ? '300px' : 'initial', // ie shit
  },
};

const buttons: {
  [key: string]: CSSProperties;
} = {
  btn: {
    borderRadius: 5,
    display: 'inline-block',
    marginBottom: 25,
    textDecoration: 'none',
    fontSize: 14,
    padding: 10,
    lineHeight: 1,
    cursor: 'pointer',
  },
  blue: {
    backgroundColor: colors.primary,
    color: colors.white,
  },
  orange: {
    backgroundColor: colors.secondary,
    color: colors.white,
  },
  fixed: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
};

const dashboard: {
  [key: string]: CSSProperties;
} = {
  segmentContainer: {},
};

const header: {
  [key: string]: CSSProperties;
} = {
  logo: {
    cursor: 'pointer',
    lineHeight: 0,
  },
  logoImage: {
    height: 25,
  },
};

const styles = {
  base,
  admin,
  article,
  counters,
  buttons,
  dashboard,
  header,
};

export default styles;
