import { LinearProgress, Paper } from '@mui/material';
import { AlertNoOrdersFound, OrdersTable, PageHeader } from '../components';
import styles from '../constants/styles';
import { useOrders } from '../hooks/api';

export function Orders() {
  const { orders, loading } = useOrders();

  return (
    <>
      <PageHeader
        heading={'Ordrar från ATORP'}
        text={'Här ser du samtliga ordrar från ATORP'}
      />

      <Paper className="Messages" style={{ ...styles.base.paper }}>
        {loading ? (
          <LinearProgress />
        ) : orders?.length > 0 ? (
          <OrdersTable orders={orders} />
        ) : (
          <AlertNoOrdersFound />
        )}
      </Paper>
    </>
  );
}
