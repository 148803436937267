import { LinearProgress, Paper } from '@mui/material';
import {
  AlertNoDeliveriesFound,
  DeliveriesTable,
  PageHeader,
} from '../components';
import styles from '../constants/styles';
import { useDeliveries } from '../hooks/api';

export function Deliveries() {
  const { deliveries, loading } = useDeliveries();

  return (
    <>
      <PageHeader
        heading={'Leveranser från ATORP'}
        text={
          'Här ser du samtliga leveranser från ATORP under de senaste 12 månaderna'
        }
      />
      <Paper className="Deliveries" style={{ ...styles.base.paper }}>
        {loading ? (
          <LinearProgress />
        ) : deliveries?.length > 0 ? (
          <DeliveriesTable deliveries={deliveries} />
        ) : (
          <AlertNoDeliveriesFound />
        )}
      </Paper>
    </>
  );
}
