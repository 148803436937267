/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Shipment } from '../models/Shipment';
import type { ShipmentDetails } from '../models/ShipmentDetails';
import type { TrackingLinkResponse } from '../models/TrackingLinkResponse';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ShipmentsService {
    /**
     * @param customerId
     * @returns Shipment Success
     * @throws ApiError
     */
    public static getCustomersShipments(
        customerId: number,
    ): CancelablePromise<Array<Shipment>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/customers/{customerId}/shipments',
            path: {
                'customerId': customerId,
            },
        });
    }
    /**
     * @param customerId
     * @param shipmentId
     * @returns TrackingLinkResponse Success
     * @throws ApiError
     */
    public static getCustomersShipmentsGetTrackingLink(
        customerId: number,
        shipmentId: number,
    ): CancelablePromise<TrackingLinkResponse> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/customers/{customerId}/shipments/{shipmentId}/getTrackingLink',
            path: {
                'customerId': customerId,
                'shipmentId': shipmentId,
            },
        });
    }
    /**
     * @param customerId
     * @param shipmentId
     * @returns ShipmentDetails Success
     * @throws ApiError
     */
    public static getCustomersShipmentsDetails(
        customerId: number,
        shipmentId: number,
    ): CancelablePromise<ShipmentDetails> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/customers/{customerId}/shipments/{shipmentId}/details',
            path: {
                'customerId': customerId,
                'shipmentId': shipmentId,
            },
        });
    }
}
