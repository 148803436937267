import { createTheme } from '@mui/material';
import { darken, lighten } from '@mui/system';
import colors from './colors';

export let theme = createTheme({
  palette: {
    primary: {
      light: lighten(colors.primary, 0.1),
      main: colors.primary,
      dark: darken(colors.primary, 0.1),
    },
    secondary: {
      light: lighten(colors.secondary, 0.1),
      main: colors.secondary,
      dark: darken(colors.secondary, 0.1),
    },
  },
  typography: {
    fontFamily: ['Varela Round'].join(','),
  },
  shape: {
    borderRadius: 8,
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: colors.primary,
          height: '100%',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
        },
        contained: {
          boxShadow: 'none',
          '&:active': {
            boxShadow: 'none',
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          marginLeft: theme.spacing(1),
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          backgroundColor: colors.white,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          margin: '0 16px',
          minWidth: 0,
          padding: 0,
          [theme.breakpoints.up('md')]: {
            padding: 0,
            minWidth: 0,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1),
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 4,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: colors.darkGray,
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'inherit',
          marginRight: 0,
          '& svg': {
            fontSize: 20,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32,
        },
      },
    },
    MuiCircularProgress: {
      defaultProps: {
        color: 'secondary',
      },
    },
    MuiLinearProgress: {
      defaultProps: { variant: 'query', color: 'secondary' },
      styleOverrides: {
        root: {
          height: 6,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          whiteSpace: 'nowrap',
        },
      },
    },
    MuiTableCell: {
      defaultProps: {
        variant: 'body',
      },
      styleOverrides: {
        root: {
          whiteSpace: 'nowrap',
        },
      },
      variants: [
        {
          props: { variant: 'body' },
          style: {},
        },
        {
          props: { variant: 'link' },
          style: {
            cursor: 'pointer',
            color: colors.primary,
            textDecoration: 'underline',
          },
        },
        {
          props: { variant: 'head' },
          style: {
            fontWeight: 'bold',
          },
        },
      ],
    },
    MuiDialog: {
      styleOverrides: {
        container: {},
        paper: {},
      },
    },
  },
};
