import {
  Drawer,
  DrawerProps,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { styled } from '@mui/system';
import React from 'react';

import {
  AccountCircle,
  BarChart,
  Chat,
  ComputerOutlined,
  Favorite,
  LocalShipping,
  PowerSettingsNew,
  Receipt,
} from '@mui/icons-material';
import { NavLink, To, useNavigate } from 'react-router-dom';
import colors from '../constants/colors';
import images from '../constants/images';
import { ROUTE_PATHS } from '../constants/routes';

const PAGES: Array<{
  id: string;
  children: Array<{
    id: string;
    path: To;
    icon: React.ReactNode;
  }>;
}> = [
  {
    id: 'Portal',
    children: [
      { id: 'Översikt', path: ROUTE_PATHS.DASHBOARD, icon: <BarChart /> },
      { id: 'Dina artiklar', path: ROUTE_PATHS.ARTICLES, icon: <Favorite /> },
      {
        id: 'Dina leveranser',
        path: ROUTE_PATHS.DELIVERIES,
        icon: <LocalShipping />,
      },
      { id: 'Dina ordrar', path: ROUTE_PATHS.ORDERS, icon: <Receipt /> },
      { id: 'Kontakta ATORP', path: ROUTE_PATHS.MESSAGES, icon: <Chat /> },
      {
        id: 'Introduktionsfilm Kundportal',
        path: ROUTE_PATHS.PORTAL_INFO,
        icon: <ComputerOutlined />,
      },
      { id: 'Min Profil', path: ROUTE_PATHS.PROFILE, icon: <AccountCircle /> },
    ],
  },
];

const LogoContainer = styled(ListItem)(({ theme }) => ({
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  backgroundColor: colors.primaryDark,
  pointer: 'cursor',
  gap: theme.spacing(2),
}));

const StyledListItem = styled(ListItem)(() => ({
  paddingTop: 0,
  paddingBottom: 0,
  backgroundColor: colors.primary,
  color: colors.white,
  '&:hover,&:focus': {
    backgroundColor: colors.primaryLight,
  },
}));

const StyledListItemLink = styled(NavLink)(() => ({
  color: colors.white,
  textDecoration: 'none',
  paddingTop: 10,
  paddingBottom: 10,
  display: 'block',
  fontSize: 'inherit',
}));

type Props = {
  onToggleDrawer?: () => void;
} & DrawerProps;

function Navigator(props: Props) {
  const navigate = useNavigate();
  const { onToggleDrawer, ...rest } = props || {};

  return (
    <Drawer variant="permanent" {...rest}>
      <List disablePadding>
        <LogoContainer
          style={{ cursor: 'pointer' }}
          onClick={() => {
            onToggleDrawer && onToggleDrawer();
            navigate(ROUTE_PATHS.DASHBOARD);
          }}
        >
          <img src={images.logo} style={{ width: '8rem' }} alt="logo" />
          <img src={images.slogan} style={{ width: '8rem' }} alt="slogan" />
        </LogoContainer>
        {PAGES.map(({ id, children }) => (
          <React.Fragment key={id}>
            {children.map(({ id: childId, path, icon }) => (
              <StyledListItem key={childId}>
                <ListItemIcon style={{ minWidth: 'auto', marginRight: '1rem' }}>
                  {icon}
                </ListItemIcon>
                <ListItemText>
                  <StyledListItemLink
                    onClick={() => onToggleDrawer && onToggleDrawer()}
                    to={path}
                  >
                    {childId}
                  </StyledListItemLink>
                </ListItemText>
              </StyledListItem>
            ))}
            <StyledListItem>
              <ListItemIcon style={{ minWidth: 'auto', marginRight: '1rem' }}>
                <PowerSettingsNew />
              </ListItemIcon>
              <ListItemText>
                <StyledListItemLink to={ROUTE_PATHS.SIGN_OUT_OIDC}>
                  Logga ut
                </StyledListItemLink>
              </ListItemText>
            </StyledListItem>
          </React.Fragment>
        ))}
      </List>
    </Drawer>
  );
}

export default Navigator;
