import { LinearProgress, Paper, Typography } from '@mui/material';
import styles from '../../constants/styles';
import { useDeliveries } from '../../hooks/api';
import { AlertNoDeliveriesFound } from '../Alerts/AlertNoDeliveriesFound';
import { DeliveriesTable } from '../Deliveries/DeliveriesTable';

export function LatestDeliveries() {
  const { deliveries, loading } = useDeliveries();
  const latestDeliveries = deliveries.slice(0, 5);

  return (
    <>
      <Typography style={{ marginBottom: 10 }} component="h5" variant="h5">
        Senaste leveranser
      </Typography>
      <Paper style={styles.base.paper}>
        {loading ? (
          <LinearProgress />
        ) : latestDeliveries?.length > 0 ? (
          <DeliveriesTable deliveries={latestDeliveries} />
        ) : (
          <AlertNoDeliveriesFound />
        )}
      </Paper>
    </>
  );
}
